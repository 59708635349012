import React from 'react';
import styled from "styled-components";
import RegisterContainer from "../containers/RegisterContainer";

const Styled = styled.div`
.form-btn{
  display: block;
  width: 100%;
  font-family: 'Gilroy-Bold', sans-serif;
}
`;

const RegisterPage = ({...rest}) => {
    return (
        <Styled>
          <RegisterContainer {...rest} />
        </Styled>
    );
};

export default RegisterPage;