import React, {useEffect, useMemo} from 'react';
import {useStore} from "../../../../store";
import {get} from "lodash";
import GridView from "../../../../containers/grid-view/grid-view";
import {KEYS} from "../../../../constants/key";
import {URLS} from "../../../../constants/url";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";

const ListContainer = ({...rest}) => {
    const {t} = useTranslation()

    const setBreadcrumbs = useStore(state => get(state, 'setBreadcrumbs', () => {
    }))
    const breadcrumbs = useMemo(() => [
        {
            id: 1,
            title: 'Главная страница',
            path: '/',
        },
        {
            id: 2,
            title: 'Все заявки',
            path: '/osago',
        }
    ], [])

    useEffect(() => {
        setBreadcrumbs(breadcrumbs)
    }, [])


    return (
        <>
            <GridView
                tableHeaderData={[
                    {
                        id: 3,
                        key: 'seria',
                        title: 'Policy seria',
                    },
                    {
                        id: 4,
                        key: 'number',
                        title: 'Policy number',
                    },
                    {
                        id: 55,
                        key: 'applicant',
                        title: 'Applicant',
                        render: ({row}) => get(row, 'applicant.person') ? `${get(row, 'applicant.person.fullName.lastname')} ${get(row, 'applicant.person.fullName.firstname')}  ${get(row, 'applicant.person.fullName.middlename')}` : get(row, 'applicant.organization.name')
                    },
                    {
                        id: 44,
                        key: 'vehicle',
                        title: 'Vehicle',
                        render: ({row}) => get(row, 'vehicle.modelCustomName')
                    },
                    {
                        id: 666,
                        key: 'amount',
                        title: 'Insurance amount',
                        render: ({value}) => <NumberFormat displayType={'text'} thousandSeparator={' '} value={value}/>
                    },
                    {
                        id: 6,
                        key: 'cost.insurancePremium',
                        title: 'Insurance premium',
                        render: ({value}) => <NumberFormat displayType={'text'} thousandSeparator={' '} value={value}/>
                    },
                    {
                        id: 7,
                        key: 'cost.sumInsured',
                        title: 'Insurance sum',
                        render: ({value}) => <NumberFormat displayType={'text'} thousandSeparator={' '} value={value}/>
                    },
                    {
                        id: 8,
                        key: 'cost.insurancePremiumPaidToInsurer',
                        title: 'Оплачено',
                        render: ({value, row}) => get(row, 'status') == 'payed' ?
                            <NumberFormat displayType={'text'} thousandSeparator={' '} value={value}/> : 0
                    },
                    {
                        id: 9,
                        key: 'status',
                        title: 'Status',
                    },

                ]}
                keyId={KEYS.list}
                url={URLS.list}
                title={t('Обязательное страхование гражданской ответственности владельцев транспортных средств')}
                responseDataKey={'result.docs'}
                viewUrl={'/osago/view'}
                createUrl={'/osago/create'}
                updateUrl={'/osago/update'}
                isHideColumn
                dataKey={'osago_formId'}
                deleteUrl={URLS.delete}
                deleteParam={'osago_formId'}
            />
        </>
    );
};

export default ListContainer;