export const langData = [
    {
        id:1,
        code:'uz',
        title:'UZ',
    },
    {
        id:2,
        code:'ru',
        title:'RU',
    },
    {
        id:3,
        code:'eng',
        title:'EN',
    }
]