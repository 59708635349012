import React, {useEffect, useState} from 'react';
import PatternFormat from 'react-number-format';
import {Controller} from "react-hook-form";
import {get, hasIn} from "lodash";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import styled, {css} from "styled-components";
import Label from "../../../../components/ui/label";
import {ErrorMessage} from "@hookform/error-message";

const Styled = styled.div`
  .form-input {
    display: block;
    min-width: 275px;
    width: 100%;
    padding: 12px 18px;
    color: #000;
    font-size: 16px;
    border: 1px solid #BABABA;
    border-radius: 5px;
    outline: none;
    max-width: 400px;
    font-family: 'Gilroy-Regular', sans-serif;

    &.error {
      border-color: #ef466f;
    }

    &:focus {
      border-color: #13D6D1;
    }
  }

  ${({noMaxWidth}) => noMaxWidth && css`
    .form-input {
      max-width: unset !important;
    }
  `}
`;

const PhoneInput = ({
                        control,
                        disabled = false,
                        name,
                        property,
                        errors,
                        params,
                        defaultValue = '',
                        label,
                        format = "+998 ## ### ## ##",
                        regex = /^(33|36|55|61|62|65|66|67|69|70|71|72|73|74|75|76|77|78|79|88|90|91|93|94|95|97|98|99)\d{7}$/,
                        watch = () => {
                        },
                        setValue = () => {
                        },
                        trigger = () => {
                        },

                    }) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue)
    const {t} = useTranslation()
    useEffect(() => {
        if (selectedValue) {
            if (regex?.test(String(selectedValue))) {
                setValue(name, `${selectedValue}`)
                trigger()
            }
        }
    }, [watch(name)]);

    return (
        <Styled>
            <div className={classNames("form-group")}>
                {!get(property, 'hideLabel', false) && <Label>{label ?? name}</Label>}
                <Controller
                    control={control}
                    name={name}
                    rules={params}
                    render={({field}) => (
                        <PatternFormat name={name}
                                       {...field}
                                       onValueChange={(values) => {
                                           const {floatValue} = values;
                                           setSelectedValue(floatValue)
                                       }}
                                       value={selectedValue}
                                       format={format}
                                       className={classNames('form-input ', {error: get(errors, `${name}`, false)})}
                                       mask={"_"}
                                       allowEmptyFormatting
                        />)
                    }
                />
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({messages = `${label ?? name} is required`}) => {

                        if (errors[name].type == 'required') {
                            messages = `${label ?? name} is required`;
                        }
                        if (errors[name].type == 'pattern') {
                            messages = `${label ?? name} is not valid`;
                        }
                        if (errors[name].type == 'manual') {
                            messages = `${label ?? name} ${errors[name].message}`;
                        }
                        return <small className="form-error-message"> {messages}</small>;
                    }}
                />


            </div>
        </Styled>
    );
};

export default PhoneInput;