import React, {useEffect, useMemo, useState} from 'react';
import {find, get, isEqual} from "lodash";
import {Col, Row} from "react-grid-system";
import Section from "../../../../components/section";
import Title from "../../../../components/ui/title";
import Button from "../../../../components/ui/button";
import Form from "../../../../containers/form/form";
import Flex from "../../../../components/flex";
import Field from "../../../../containers/form/field";
import {useDeleteQuery, useGetAllQuery, usePostQuery} from "../../../../hooks/api";
import {KEYS} from "../../../../constants/key";
import {URLS} from "../../../../constants/url";
import {getSelectOptionsListFromData} from "../../../../utils";
import {OverlayLoader} from "../../../../components/loader";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../../../store";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";
import Table from "../../../../components/table";
import clickImg from "../../../../assets/images/click.png"


const ViewContainer = ({osago_formId = null}) => {
    const [cost, setCost] = useState({})
    const {t} = useTranslation()
    const navigate = useNavigate();
    const setBreadcrumbs = useStore(state => get(state, 'setBreadcrumbs', () => {
    }))
    const breadcrumbs = useMemo(() => [{
        id: 1, title: 'OSAGO list', path: '/osago',
    }, {
        id: 2, title: 'OSAGO view', path: '/osago',
    }], [])


    useEffect(() => {
        setBreadcrumbs(breadcrumbs)
    }, [])
    const {data, isLoading} = useGetAllQuery({
        key: KEYS.view,
        url: URLS.view,
        params: {
            params: {
                osago_formId: osago_formId
            }
        },
        enabled: !!(osago_formId)
    })

    const {data: insuranceTerms, isLoading: isLoadingInsuranceTerms} = useGetAllQuery({
        key: KEYS.terms, url: URLS.terms
    })
    const insuranceTermsList = getSelectOptionsListFromData(get(insuranceTerms, `data.result`, []), 'ID', 'NAME')

    const {data: discounts} = useGetAllQuery({
        key: KEYS.discounts, url: URLS.discounts
    })
    const discountsList = getSelectOptionsListFromData(get(discounts, `data.result`, []), 'ID', 'NAME')


    const {data: region, isLoading: isLoadingRegion} = useGetAllQuery({
        key: KEYS.regions, url: URLS.regions
    })
    const regionList = getSelectOptionsListFromData(get(region, `data.result`, []), 'id', 'name')

    const {data: genders} = useGetAllQuery({
        key: KEYS.genders, url: URLS.genders
    })
    const genderList = getSelectOptionsListFromData(get(genders, `data.result`, []), 'id', 'name')


    const {data: vehicleTypes} = useGetAllQuery({
        key: KEYS.vehicleTypes, url: URLS.vehicleTypes
    })
    const vehicleTypeList = getSelectOptionsListFromData(get(vehicleTypes, `data.result`, []), 'id', 'name')


    const {data: relatives} = useGetAllQuery({
        key: KEYS.relatives, url: URLS.relatives
    })
    const relativeList = getSelectOptionsListFromData(get(relatives, `data.result`, []), 'ID', 'NAME')


    const {mutate: deleteRequest, isLoading: deleteLoading} = useDeleteQuery({listKeyId: KEYS.osgopDelete})

    const {
        mutate: calculatePremiumRequest
    } = usePostQuery({listKeyId: KEYS.calculator, hideSuccessToast: true})

    const {
        mutate: requestClick,
        isLoading: isLoadingRequestClick
    } = usePostQuery({listKeyId: KEYS.view})
    const {
        mutate: confirmClick,
        isLoading: isLoadingConfirmClick
    } = usePostQuery({listKeyId: KEYS.view})


    const remove = () => {
        Swal.fire({
            position: 'center',
            icon: 'error',
            backdrop: 'rgba(0,0,0,0.9)',
            background: 'none',
            title: t('Are you sure?'),
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#13D6D1',
            confirmButtonText: t('Delete'),
            cancelButtonText: t('Cancel'),
            customClass: {
                title: 'title-color',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                deleteRequest({url: `${URLS.delete}?osago_formId=${osago_formId}`}, {
                    onSuccess: () => {
                        navigate('/osago')
                    }
                })
            }
        });
    }

    const calculatePremium = (calculatorDTO) => {
        calculatePremiumRequest({
                url: URLS.calculator, attributes: {
                    regionId: get(calculatorDTO, 'vehicle.regionId'),
                    vehicleTypeId: get(calculatorDTO, 'vehicle.typeId'),
                    discount: get(calculatorDTO, 'cost.discountId'),
                    driverNumberRestriction: get(calculatorDTO, 'details.driverNumberRestriction'),
                    terms: get(calculatorDTO, 'cost.contractTermConclusionId'),
                }
            },
            {
                onSuccess: ({data: response}) => {
                    setCost(get(response, 'result'))
                }
            }
        )
    }

    useEffect(() => {
        if (get(data, 'data.result')) {
            calculatePremium(get(data, 'data.result'))
        }
    }, [get(data, 'data.result')])

    if (isLoading || isLoadingRegion) {
        return <OverlayLoader/>
    }

    const clickRequest = () => {
        requestClick({
                url: `${URLS.requestClick}?osago_formId=${osago_formId}`
            },
            {
                onSuccess: ({data: response}) => {

                }
            }
        )
    }
    const confirmRequest = () => {
        confirmClick({
                url: `${URLS.confirmClick}?osago_formId=${osago_formId}`
            },
            {
                onSuccess: ({data: response}) => {

                },
                onError: () => {

                }
            }
        )
    }
    return (<>
        {(deleteLoading || isLoadingConfirmClick || isLoadingRequestClick) && <OverlayLoader/>}
        <Section>

            <Form footer={!isEqual(get(data, 'data.result.status'), 'payed') && <Flex
                className={'mt-32'}>{(isEqual(get(data, 'data.result.status'), 'new') || isEqual(get(data, 'data.result.status'), 'edited')) && <>
                <Button onClick={remove}
                        danger type={'button'}
                        className={'mr-16'}>Удалить</Button>
            </>}
                {(isEqual(get(data, 'data.result.status'), 'new')) && <Button  yellow onClick={clickRequest}
                                                                              danger type={'button'}
                                                                              className={'mr-16'}> <Flex> <span>Оплачивайте через</span><img style={{marginLeft:'10px'}} width={48} src={clickImg}
                                                                                                                                             alt=""/></Flex></Button>}
                {(isEqual(get(data, 'data.result.status'), 'request')) && <Button green onClick={confirmRequest}
                                                                                  danger type={'button'}
                                                                                  className={'mr-16'}>Подтвердите
                    оплату</Button>}
            </Flex>}>
                <Row gutterWidth={60} className={'mt-30'}>
                    <Col xs={12} className={'mb-25'}><Title>Информация о транспортном средстве:
                    </Title></Col>
                    <Col xs={4} style={{borderRight: '1px solid #DFDFDF'}}>
                        <Row align={'center'} className={'mb-25'}>
                            <Col xs={5}>Государственный номер</Col>
                            <Col xs={7}><Field defaultValue={get(data, 'data.result.vehicle.govNumber')}
                                               property={{hideLabel: true, disabled: true}}
                                               type={'input'}
                                               name={'vehicle.govNumber'}/></Col>
                        </Row>

                        <Row align={'center'} className={'mb-25'}>
                            <Col xs={5}>Серия тех.паспорта:</Col>
                            <Col xs={7}><Field defaultValue={get(data, 'data.result.vehicle.techPassport.seria')}
                                               property={{hideLabel: true, disabled: true}}
                                               type={'input'}
                                               name={'vehicle.techPassport.seria'}/></Col>
                        </Row>
                        <Row align={'center'} className={'mb-25'}>
                            <Col xs={5}>Номер тех.паспорта:</Col>
                            <Col xs={7}><Field defaultValue={get(data, 'data.result.vehicle.techPassport.number')}
                                               property={{hideLabel: true, disabled: true}}
                                               type={'input'}
                                               name={'vehicle.techPassport.number'}/></Col>
                        </Row>
                    </Col>
                    <Col xs={8}>
                        <Row>
                            <Col xs={4} className="mb-25">
                                <Field disabled defaultValue={get(data, 'data.result.vehicle.typeId')}
                                       options={vehicleTypeList}
                                       params={{required: true}}
                                       label={'Вид транспорта'}
                                       type={'select'}
                                       name={'vehicle.typeId'}/>
                            </Col>
                            <Col xs={4} className="mb-25">
                                <Field property={{disabled: true}}
                                       defaultValue={get(data, 'data.result.vehicle.modelCustomName')}
                                       params={{required: true, disabled: true}}
                                       label={'Марка / модель'}
                                       type={'input'}
                                       name={'vehicle.modelCustomName'}/>
                            </Col>
                            <Col xs={4} className="mb-25">
                                <Field disabled defaultValue={get(data, 'data.result.vehicle.regionId')}
                                       options={regionList}
                                       params={{required: true}}
                                       label={'Регион регистрации'}
                                       type={'select'}
                                       name={'vehicle.regionId'}/>
                            </Col>

                            <Col xs={4} className="mb-25">
                                <Field disabled defaultValue={get(data, 'data.result.vehicle.issueYear')}
                                       property={{mask: '9999', maskChar: '_'}} params={{required: true}}
                                       label={'Год выпуска'}
                                       type={'input-mask'}
                                       name={'vehicle.issueYear'}/>
                            </Col>
                            <Col xs={4} className="mb-25">
                                <Field property={{disabled: true}}
                                       defaultValue={get(data, 'data.result.vehicle.bodyNumber')}
                                       params={{required: true}}
                                       label={'VIN номер'}
                                       type={'input'}
                                       name={'vehicle.bodyNumber'}/>
                            </Col>
                            <Col xs={4} className="mb-25">
                                <Field property={{disabled: true}}
                                       defaultValue={get(data, 'data.result.vehicle.engineNumber')}
                                       params={{required: true}}
                                       label={'Номер двигателя'}
                                       type={'input'}
                                       name={'vehicle.engineNumber'}/>
                            </Col>
                            <Col xs={4} className="mb-25">
                                <Field disabled defaultValue={get(data, 'data.result.vehicle.techPassportIssueDate')}
                                       params={{required: true}}
                                       label={'Дата выдачи тех.паспорта'}
                                       type={'datepicker'}
                                       name={'vehicle.techPassport.issueDate'}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutterWidth={60} className={'mt-15'}>
                    <Col xs={12} className={'mb-25'}><Title>Информация о владельце транспортного средства:
                    </Title></Col>
                    <Col xs={12}>
                        <Row>
                            <Col xs={4}>
                            </Col>
                            <Col xs={8} className={'text-right'}>
                                <Flex justify={'flex-end'}>
                                    <Field disabled
                                           defaultValue={get(data, 'data.result.applicant.person.passportData.seria')}
                                           params={{required: true}}
                                           className={'mr-16'} style={{width: 75}}
                                           property={{
                                               disabled: true,
                                               hideLabel: true,
                                               mask: 'aa',
                                               placeholder: 'AA',
                                               upperCase: true,
                                               maskChar: '_',
                                           }}
                                           name={'applicant.person.passportData.seria'}
                                           type={'input-mask'}
                                    />
                                    <Field disabled
                                           defaultValue={get(data, 'data.result.applicant.person.passportData.number')}
                                           params={{required: true}} property={{
                                        hideLabel: true,
                                        mask: '9999999',
                                        placeholder: '1234567',
                                        maskChar: '_',
                                    }} name={'applicant.person.passportData.number'} type={'input-mask'}/>

                                    <Field disabled defaultValue={get(data, 'data.result.applicant.person.birthDate')}
                                           params={{required: true}} className={'ml-15'}
                                           property={{
                                               hideLabel: true,
                                               placeholder: 'Дата рождения',
                                           }}
                                           name={'applicant.person.birthDate'} type={'datepicker'}/>
                                </Flex>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12}>
                        <hr className={'mt-15 mb-15'}/>
                    </Col>

                    <Col xs={4} className={'mb-25'}>
                        <Field property={{disabled: true}} params={{required: true}}
                               defaultValue={get(data, 'data.result.applicant.person.fullName.firstname')}
                               label={'Firstname'}
                               type={'input'}
                               name={'applicant.person.fullName.firstname'}/>
                    </Col>
                    <Col xs={4} className={'mb-25'}>
                        <Field property={{disabled: true}} params={{required: true}}
                               defaultValue={get(data, 'data.result.applicant.person.fullName.lastname')}
                               label={'Lastname'} type={'input'}
                               name={'applicant.person.fullName.lastname'}/>
                    </Col>
                    <Col xs={4} className={'mb-25'}>
                        <Field params={{required: true}} property={{disabled: true}}
                               defaultValue={get(data, 'data.result.applicant.person.fullName.middlename')}
                               label={'Middlename'}
                               type={'input'}
                               name={'applicant.person.fullName.middlename'}/>
                    </Col>
                    <Col xs={4} className={'mb-25'}>
                        <Field property={{disabled: true}}
                               defaultValue={get(data, 'data.result.applicant.person.passportData.pinfl')}
                               label={'ПИНФЛ'} type={'input'}
                               name={'applicant.person.passportData.pinfl'}/>
                    </Col>
                    <Col xs={4} className={'mb-25'}>
                        <Field property={{disabled: true}}
                               defaultValue={get(data, 'data.result.applicant.person.passportData.issuedBy')}
                               label={'Кем выдан паспорт'}
                               type={'input'}
                               name={'applicant.person.passportData.issuedBy'}/>
                    </Col>
                    <Col xs={4} className={'mb-25'}>
                        <Field disabled defaultValue={get(data, 'data.result.applicant.person.passportData.issueDate')}
                               label={'Серия выдачи паспорта'}
                               type={'datepicker'}
                               name={'applicant.person.passportData.issueDate'}/>
                    </Col>
                    <Col xs={4} className={'mb-25'}>
                        <Field disabled params={{required: true}}
                               defaultValue={get(data, 'data.result.applicant.person.gender')}
                               options={genderList}
                               label={'Gender'}
                               type={'select'}
                               name={'applicant.person.gender'}/>
                    </Col>
                    <Col xs={4} className={'mb-25'}>
                        <Field

                            params={{
                                required: true,
                                pattern: {
                                    value: /^998(9[012345789]|6[125679]|7[01234569])[0-9]{7}$/,
                                    message: 'Invalid format'
                                }
                            }}
                            defaultValue={get(data, 'data.result.applicant.person.phoneNumber')}
                            label={'Phone'}
                            type={'input'}
                            property={{placeholder: '998XXXXXXXXX', disabled: true}}
                            name={'applicant.person.phoneNumber'}/>
                    </Col>
                    <Col xs={4} className={'mb-25'}>
                        <Field property={{disabled: true}} params={{required: true}}
                               defaultValue={get(data, 'data.result.applicant.address')}
                               label={'Address'}
                               type={'input'}
                               name={'applicant.address'}/>
                    </Col>
                    <Col xs={4} className={'mb-25'}>
                        <Field disabled defaultValue={String(get(data, 'data.result.cost.discountId'))}
                               params={{required: true}}
                               label={'Наличие льгот'}
                               type={'select'}
                               options={discountsList}
                               name={'cost.discountId'}/>
                    </Col>
                    <Col xs={4}>
                        <Field
                            property={{disabled: true}}
                            params={{required: true}}
                            defaultValue={get(data, 'data.result.applicant.person.licenseSeria')}
                            label={'Серия вод.удостоверения'}
                            type={'input'}
                            name={'applicant.person.licenseSeria'}/>
                    </Col>
                    <Col xs={4}>
                        <Field
                            property={{disabled: true}}
                            defaultValue={get(data, 'data.result.applicant.person.licenseNumber')}
                            params={{required: true}}
                            label={'Номер вод.удостоверения'}
                            type={'input'}
                            name={'applicant.person.licenseNumber'}/>
                    </Col>
                    <Col xs={4} className={'mt-15'}>
                        <Field
                            disabled
                            params={{required: true}}
                            defaultValue={get(data, 'data.result.applicant.person.licenseIssueDate')}
                            label={'Дата вод.удостоверения'}
                            type={'datepicker'}
                            name={'applicant.person.licenseIssueDate'}/>
                    </Col>
                </Row>
                <Row gutterWidth={60} className={'mt-30'}>
                    <Col xs={12} className={'mb-25'}><Title>Информация о допущенных к управлению транспортным средством
                        лицах:
                    </Title></Col>
                    <Col xs={12}>
                        <div className={'horizontal-scroll '}>
                            <Table bordered hideThead={false}
                                   thead={['Фамилия ', 'Имя', 'Отчество', 'Степень родства']}>
                                {
                                    get(data, 'data.result.drivers', []).map((item, index) => <tr>
                                        <td>{get(item, 'fullName.lastname')}</td>
                                        <td>{get(item, 'fullName.firstname')}</td>
                                        <td>{get(item, 'fullName.middlename')}</td>
                                        {/*<td>{upperCase(get(item, 'passportData.seria', ''))}</td>*/}
                                        {/*<td>{get(item, 'passportData.number')}</td>*/}
                                        {/*<td>{get(item, 'passportData.pinfl')}</td>*/}
                                        {/*<td>{get(item, 'passportData.issueDate')}</td>*/}
                                        {/*<td>{get(item, 'licenseSeria')}</td>*/}
                                        {/*<td>{get(item, 'licenseNumber')}</td>*/}
                                        {/*<td>{get(item, 'licenseIssueDate')}</td>*/}
                                        <td>{get(find(relativeList, (r) => get(r, 'value') == String(get(item, 'relative'))), 'label')}</td>
                                    </tr>)
                                }
                            </Table>
                        </div>
                    </Col>
                </Row>
                <Row gutterWidth={60} className={'mt-30'}>
                    <Col xs={12} className={'mb-15'}><Title>Срок страхования:
                    </Title></Col>
                    <Col xs={4} className={'mb-25'}>
                        <Field disabled defaultValue={String(get(data, 'data.result.cost.contractTermConclusionId'))}
                               options={insuranceTermsList} params={{required: true}}
                               label={'Срок страхования'}
                               type={'select'}
                               name={'insuranceTermId'}/>
                    </Col>
                    <Col xs={4} className={'mb-25'}>
                        <Field
                            disabled
                            defaultValue={String(get(data, 'data.result.details.startDate'))}
                            label={'Дата начала покрытия'}
                            params={{required: true}}
                            property={{
                                dateFormat: 'dd.MM.yyyy'
                            }}
                            type={'datepicker'}
                            name={'details.startDate'}/>
                    </Col>

                    <Col xs={4}>
                        <Field
                            label={'Дата окончания покрытия'}
                            params={{required: true}}
                            disabled
                            defaultValue={String(get(data, 'data.result.details.endDate'))}
                            property={{dateFormat: 'dd.MM.yyyy'}} type={'datepicker'}
                            name={'details.endDate'}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className={'mb-25'}>
                        <Field
                            defaultValue={get(cost, 'sum.insuranceSum', 0)}
                            property={{disabled: true}}
                            label={'Страховая сумма'}
                            type={'number-format-input'}
                            name={'insuranceSum'}/>
                    </Col>
                    <Col xs={6} className={'mb-25'}>
                        <Field
                            defaultValue={get(cost, 'sum.insurancePremium', 0)}
                            property={{disabled: true}}
                            label={'Страховая премия'}
                            type={'number-format-input'}
                            name={'insurancePremium'}/>
                    </Col>
                    <Col xs={6} className={'mb-25'}>
                        <Field
                            defaultValue={get(cost, 'sum.discountSum', 0)}
                            property={{disabled: true}}
                            label={'Скидка'}
                            type={'number-format-input'}
                            name={'discountSum'}/>
                    </Col>
                    <Col xs={6} className={'mb-25'}>
                        <Field
                            defaultValue={get(cost, 'sum.insurancePremiumPaidToInsurer', 0)}
                            property={{disabled: true}}
                            label={'Премия к оплате'}
                            type={'number-format-input'}
                            name={'insurancePremiumPaidToInsurer'}/>
                    </Col>
                </Row>
            </Form>
        </Section>
    </>);
};

export default ViewContainer;