import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Form from "../../../containers/form/form";
import Field from "../../../containers/form/field";
import Button from "../../../components/ui/button";
import {usePostQuery} from "../../../hooks/api";
import {useSettingsStore} from "../../../store";
import {get} from "lodash";
import Swal from "sweetalert2";
import {OverlayLoader} from "../../../components/loader";
import {URLS} from "../../../constants/url";
import i18next from "i18next";
import {useTranslation} from "react-i18next";

const LoginContainer = ({...rest}) => {
    const {t} = useTranslation()
    const {mutate, isLoading} = usePostQuery({url: URLS.login, hideSuccessToast: true})

    const setToken = useSettingsStore(state => get(state, 'setToken', () => {
    }))
    const setTranslateToken = useSettingsStore(state => get(state, 'setTranslateToken', () => {
    }))
    const navigate = useNavigate();

    const loginRequest = ({data}) => {
        const {phone,...rest} = data;
        mutate({url: URLS.login, attributes: {...rest,phone:`998${phone}`}}, {
            onSuccess: ({data}) => {
                setToken(get(data, 'result.access_token', null))
                setTranslateToken(get(data, 'result.alfa_token', null))
                navigate("/osgor");
                i18next.reloadResources()
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    backdrop: 'rgba(0,0,0,0.9)',
                    background: 'none',
                    title: t('Добро пожаловать в нашу систему'),
                    showConfirmButton: false,
                    timer: 2000,
                    customClass: {
                        title: 'title-color',
                    },
                });
            }
        })
    }

    if (isLoading) {
        return <OverlayLoader/>
    }

    return (
        <div>
            <Form formRequest={loginRequest}
                  footer={<Button className={'form-btn'} type={'submit'}>{t("Войти")}</Button>}>
                <Field name={'phone'} type={'phone-number'} label={t('Номер телефона')} params={{
                    required: true,
                    pattern: {
                        value: /^(33|36|55|61|62|65|66|67|69|70|71|72|73|74|75|76|77|78|79|88|90|91|93|94|95|97|98|99)\d{7}$/,
                        message: 'Invalid format'
                    }
                }}
                       property={{placeholder: t('Номер телефона')}}
                />
                <Field name={'password'} type={'input'} label={t('Пароль')}
                       params={{required: true}} property={{placeholder: t('Пароль'), type: 'password'}}/>

            </Form>
            <Link className={'forgot_password'} to={'#'}>{t('Забыли пароль?')}</Link>
            <div className={'splitter'}>
                <span className={'splitter_line'}></span>
                <span className={'splitter_text'}>{t('или')}</span>
                <span className={'splitter_line'}></span>
            </div>
            <Link className={'register'} to={'/auth/register'}>Создать аккаунт</Link>
        </div>
    );
};

export default LoginContainer;