import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Form from "../../../containers/form/form";
import Field from "../../../containers/form/field";
import Button from "../../../components/ui/button";
import {usePostQuery} from "../../../hooks/api";
import {OverlayLoader} from "../../../components/loader";
import {URLS} from "../../../constants/url";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

const RegisterContainer = ({...rest}) => {
    const {t} = useTranslation()
    const {mutate, isLoading} = usePostQuery({url: URLS.login, hideSuccessToast: true})

    const navigate = useNavigate();

    const registerRequest = ({data}) => {
        const {phone, ...rest} = data;
        mutate({url: URLS.register, attributes: {...rest, phone: `998${phone}`}}, {
            onSuccess: ({data}) => {
                toast.success('Успешно зарегистрирован!!!')
                navigate("/auth");
            }
        })
    }

    if (isLoading) {
        return <OverlayLoader/>
    }

    return (
        <div>
            <Form formRequest={registerRequest}
                  footer={<Button className={'form-btn'} type={'submit'}>{t("Создать аккаунт")}</Button>}>
                <Field name={'firstName'} type={'input'} label={t('Имя')}
                       property={{placeholder: t('Имя')}}/>
                <Field name={'lastName'} type={'input'} label={t('Фамилия')}
                       property={{placeholder: t('Фамилия')}}/>
                <Field name={'phone'} type={'phone-number'} label={t('Номер телефона')} params={{
                    required: true,
                    pattern: {
                        value: /^(33|36|55|61|62|65|66|67|69|70|71|72|73|74|75|76|77|78|79|88|90|91|93|94|95|97|98|99)\d{7}$/,
                        message: 'Invalid format'
                    }
                }}
                       property={{placeholder: t('Номер телефона')}}
                />
                <Field name={'password'} type={'input'} label={t('Пароль')}
                       params={{
                           required: true,
                           pattern: {
                               value: /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                               message: 'is too weak'
                           }
                       }} property={{placeholder: t('Пароль'), type: 'password'}}/>
                <Field  name={'passwordConfirm'} type={'input'} label={t('Подтвердить пароль')}
                        params={{
                            required: true,
                            pattern: {
                                value: /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                                message: 'is too weak'
                            }
                        }} property={{placeholder: t('Подтвердить пароль'), type: 'password'}}/>

            </Form>
            <div className={'splitter'}>
                <span className={'splitter_line'}></span>
                <span className={'splitter_text'}>{t('или')}</span>
                <span className={'splitter_line'}></span>
            </div>
            <Link className={'register'} to={'/auth'}>Войти</Link>
        </div>
    );
};

export default RegisterContainer;