import React from 'react';
import styled from "styled-components";
import Section from "../../../components/section";
import {Col, Row} from "react-grid-system";
import osgorImg from "../../../assets/images/osgor.jpg"
import osgopImg from "../../../assets/images/osgop.jpg"
import osagoImg from "../../../assets/images/osaga.jpg"
import Button from "../../../components/ui/button";
import {useNavigate} from "react-router-dom";

const Styled = styled.div`
  .box {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.06);
    padding: 15px;
    border-radius: 5px;
  }
`;
const HomePage = ({...rest}) => {
    const navigate = useNavigate()
    return (
        <Styled {...rest}>
            <Section>
                <Row gutterWidth={40}>
                    <Col xs={3}>
                        <div className={'box'}>
                            <img className={'img-fluid'} src={osgorImg} alt=""/>
                            <Button onClick={() => navigate('/osgor/create')}
                                    style={{width: '100%', marginTop: '15px'}}>Купить ОСГОР</Button>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <div className={'box'}>
                            <img className={'img-fluid'} src={osgopImg} alt=""/>
                            <Button onClick={() => navigate('/osgop/create')}
                                    style={{width: '100%', marginTop: '15px'}}>Купить ОСГОП</Button>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <div className={'box'}>
                            <img className={'img-fluid'} src={osagoImg} alt=""/>
                            <Button onClick={() => navigate('/osago/create')} style={{width: '100%', marginTop: '15px'}}>Купить ОСАГО</Button>
                        </div>
                    </Col>
                </Row>
            </Section>
        </Styled>
    );
};

export default HomePage;