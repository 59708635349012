export const KEYS = {
    getMeAlfa: 'getMeAlfa',
    getMe: 'getMe',
    login: 'login',
    osgorCreate: 'osgorCreate',
    osgorList: 'osgorList',
    osgorView: 'osgorView',
    osgorDelete: 'osgorDelete',
    osgorEdit: 'osgorEdit',
    osgorConfirmPayment: 'osgorConfirmPayment',
    osgorCheckPayment: 'osgorCheckPayment',
    osgorEpolis: 'osgorEpolis',
    osgopCreate: 'osgopCreate',
    osgopView: 'osgopView',
    osgopDelete: 'osgopDelete',
    osgopList: 'osgopList',
    osgopConfirmPayment: 'osgopConfirmPayment',
    osgopCheckPayment: 'osgopCheckPayment',
    osgopEpolis: 'osgopEpolis',
    personalInfoProvider: 'personalInfoProvider',
    personalInfoNonCitizenProvider: 'personalInfoNonCitizenProvider',
    organizationInfoProvider: 'organizationInfoProvider',
    vehicleInfoProvider: 'vehicleInfoProvider',
    vehicleInfoForeignProvider: 'vehicleInfoForeignProvider',
    driverLicenceProvider: 'driverLicenceProvider',
    passengerLicenceProvider: 'passengerLicenceProvider',
    isPensionerProvider: 'isPensionerProvider',
    isDisabledProvider: 'isDisabledProvider',
    regions: 'regions',
    districts: 'districts',
    countries: 'countries',
    genders: 'genders',
    residentTypes: 'residentTypes',
    areaTypes: 'areaTypes',
    insuranceTerms: 'insuranceTerms',
    ownershipForms: 'ownershipForms',
    agencies: 'agencies',
    vehicleTypes: 'vehicleTypes',
    okeds: 'okeds',
    activityAndRisk: 'activityAndRisk',
    osgorCalculate: 'osgorCalculate',
    agents: 'agents',
    getRatio: 'getRatio',
    osgopCalculate: 'osgopCalculate',
    create: 'create',
    driverInfo: 'driverInfo',
    calculator: 'calculator',
    vehicleInfo: 'vehicleInfo',
    relatives: 'relatives',
    termsSeason: 'termsSeason',
    terms: 'terms',
    discounts: 'discounts',
    requestClick: 'requestClick',
    confirmClick: 'confirmClick',
    list: 'osagolist',
    view: 'osagoview',
}