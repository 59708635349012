export const menuData = [

    {
        id: 1,
        title: 'Мои заявки',
        path: '/',
        submenu: [
            {
                id: 1,
                title: 'OSGOR',
                path: '/osgor',
            },
            {
                id: 2,
                title: 'OSGOP',
                path: '/osgop',
            },
            {
                id: 3,
                title: 'ОСГОВТС',
                path: '/osago',
            }
        ]
    },
    // {
    //     id: 2,
    //     title: 'OSGOP',
    //     path: '/osgop',
    // },

]