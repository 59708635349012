import React from 'react';

const Auth = ({children, ...rest}) => {




    return (
        <>
            {children}
        </>
    );
};

export default Auth;