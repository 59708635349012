export const URLS = {
    getMeAlfa: 'user/f/getme',
    getMe: 'api/auth/profile',
    forgotPassword: 'api/auth/edit',
    login: 'api/auth/login',
    register: 'api/auth/register',
    osgorCreate: 'api/osgor/create',
    osgorList: 'api/osgor/list',
    osgorView: 'api/osgor/show',
    osgorDelete: 'api/osgor/delete',
    osgorEdit: 'api/osgor/edit',
    osgorSendFond: 'api/osgor/send',
    osgorConfirmPayment: 'api/osgor/confirm-payed',
    osgorCheckPayment: 'api/osgor/check-payment',
    osgorEpolis: 'api/osgor/get-e-polis',
    osgopCreate: 'api/osgop/create',
    osgopView: 'api/osgop/show',
    osgopDelete: 'api/osgop/delete',
    osgopList: 'api/osgop/list',
    osgopConfirmPayment: 'api/osgop/confirm-payed',
    osgopCheckPayment: 'api/osgop/check-payment',
    osgopEpolis: 'api/osgop/get-e-polis',
    osgopSendFond: 'api/osgop/send',
    personalInfoProvider: 'api/provider/personal-info',
    personalInfoNonCitizenProvider: 'api/provider/personal-info-non-citizen',
    organizationInfoProvider: 'api/provider/organization-info',
    vehicleInfoProvider: 'api/provider/vehicle-info',
    vehicleInfoForeignProvider: 'api/provider/vehicle-info-foreign',
    driverLicenceProvider: 'api/provider/driver-license',
    passengerLicenceProvider: 'api/provider/passenger-licence',
    isPensionerProvider: 'api/provider/is-pensioner',
    isDisabledProvider: 'api/provider/is-disabled',
    regions: 'api/references/regions',
    districts: 'api/references/districts',
    countries: 'api/references/countries',
    genders: 'api/references/genders',
    residentTypes: 'api/references/resident-types',
    areaTypes: 'api/references/area-types',
    insuranceTerms: 'api/references/insurance-terms',
    ownershipForms: 'api/references/ownership-forms',
    agencies: 'api/references/agencies',
    agents: 'api/references/agents',
    vehicleTypes: 'api/references/vehicle-types',
    okeds: 'api/references/okeds',
    activityAndRisk: 'api/references/activity-and-risks',
    osgorCalculate: 'api/osgor/calculate',
    getRatio: 'api/osgop/ratio',
    osgopCalculate: 'api/osgop/calculate',
    create: 'api/osago/create',
    view: 'api/osago/show',
    list: 'api/osago/list',
    delete: 'api/osago/delete',
    edit: 'api/osago/edit',
    send: 'api/osago/send',
    confirmPayment: 'api/osago/confirm-payed',
    checkPayment: 'api/osago/check-payment',
    ePolis: 'api/osago/get-e-polis',
    relatives: 'api/osago/relatives',
    terms: 'api/osago/terms',
    termsSeason: 'api/osago/terms-season',
    discounts: 'api/osago/discounts',
    calculator: 'api/osago/calculate',
    requestClick: 'api/osago/request-click',
    confirmClick: 'api/osago/confirm-click',
}