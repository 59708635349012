import React, {useEffect, useMemo, useState} from 'react';
import {useStore} from "../../../../store";
import {every, find, get, isEqual, isNil, toUpper, upperCase, values} from "lodash";
import {Col, Row} from "react-grid-system";
import Section from "../../../../components/section";
import Title from "../../../../components/ui/title";
import Button from "../../../../components/ui/button";
import Form from "../../../../containers/form/form";
import Flex from "../../../../components/flex";
import Field from "../../../../containers/form/field";
import {useGetAllQuery, usePostQuery} from "../../../../hooks/api";
import {KEYS} from "../../../../constants/key";
import {URLS} from "../../../../constants/url";
import {getSelectOptionsListFromData} from "../../../../utils";
import {OverlayLoader} from "../../../../components/loader";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import Checkbox from 'rc-checkbox';
import Modal from "../../../../components/modal";
import Table from "../../../../components/table";
import {Info, Trash2} from "react-feather";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";

const getEndDateByInsuranceTerm = (term, startDate) => {
    if (!isNil(term)) {
        return dayjs(startDate).add(get(term, 'value'), get(term, 'prefix')).toDate()
    }
    return dayjs()
}

const OsagaCreateContainer = () => {
    const [owner, setOwner] = useState('person')
    const [calculatorDTO, setCalculatorDTO] = useState({
        vehicleTypeId: null,
        regionId: null,
        discount: null,
        terms: null,
        driverNumberRestriction: true
    })
    const [ownerPerson, setOwnerPerson] = useState(null)
    const [vehicle, setVehicle] = useState(null)
    const [passportSeries, setPassportSeries] = useState(null)
    const [passportNumber, setPassportNumber] = useState(null)
    const [birthDate, setBirthDate] = useState(null)
    const [insuranceTerm, setInsuranceTerm] = useState(null)
    const [policeStartDate, setPoliceStartDate] = useState(dayjs())
    const [govNumber, setGovNumber] = useState(null)
    const [techPassportSeria, setTechPassportSeria] = useState(null)
    const [techPassportNumber, setTechPassportNumber] = useState(null)
    const [cost, setCost] = useState(null)
    const [visible, setVisible] = useState(false)
    const [driverPassportSeries, setDriverPassportSeries] = useState(null)
    const [driverPassportNumber, setDriverPassportNumber] = useState(null)
    const [driverInps, setDriverInps] = useState(null)
    const [driver, setDriver] = useState(null)
    const [driverLicense, setDriverLicense] = useState({
        data: null,
        pinfl: null,
        passportSeries: null,
        passportNumber: null
    })
    const [hasDrivers, setHasDrivers] = useState(true)
    const [drivers, setDrivers] = useState([])
    const [ownerDriver, setOwnerDriver] = useState(null)
    const navigate = useNavigate();
    const [otherParams, setOtherParams] = useState({})
    const {t} = useTranslation()

    const setBreadcrumbs = useStore(state => get(state, 'setBreadcrumbs', () => {
    }))
    const breadcrumbs = useMemo(() => [{
        id: 1, title: 'Главная страница', path: '/osago',
    }, {
        id: 2, title: 'Добавить ', path: '/osago/create',
    }], [])
    const user = useStore((state) => get(state, 'user'))


    useEffect(() => {
        setBreadcrumbs(breadcrumbs)
    }, [])

    const {data: isPensioner} = useGetAllQuery({
        key: KEYS.isPensionerProvider, url: URLS.isPensionerProvider,
        enabled: !!(get(otherParams, 'applicant.person.passportData.pinfl') && get(otherParams, 'applicant.person.passportData.seria') && get(otherParams, 'applicant.person.passportData.number')),
        params: {
            params: {
                pinfl: get(otherParams, 'applicant.person.passportData.pinfl'),
                series: get(otherParams, 'applicant.person.passportData.seria'),
                number: get(otherParams, 'applicant.person.passportData.number'),
            }
        }
    })
    const {data: isDisabled} = useGetAllQuery({
        key: KEYS.isDisabledProvider, url: URLS.isDisabledProvider,
        enabled: !!(get(otherParams, 'applicant.person.passportData.pinfl')),
        params: {
            params: {
                pinfl: get(otherParams, 'applicant.person.passportData.pinfl'),
            }
        }
    })
    const {data: insuranceTerms, isLoading: isLoadingInsuranceTerms} = useGetAllQuery({
        key: KEYS.terms, url: URLS.terms
    })
    const insuranceTermsList = getSelectOptionsListFromData(get(insuranceTerms, `data.result`, []), 'ID', 'NAME')


    const {data: region, isLoading: isLoadingRegion} = useGetAllQuery({
        key: KEYS.regions, url: URLS.regions
    })
    const regionList = getSelectOptionsListFromData(get(region, `data.result`, []), 'id', 'name')

    const {data: genders} = useGetAllQuery({
        key: KEYS.genders, url: URLS.genders
    })
    const genderList = getSelectOptionsListFromData(get(genders, `data.result`, []), 'id', 'name')


    const {data: vehicleTypes} = useGetAllQuery({
        key: KEYS.vehicleTypes, url: URLS.vehicleTypes
    })
    const vehicleTypeList = getSelectOptionsListFromData(get(vehicleTypes, `data.result`, []), 'id', 'name')


    const {data: relatives} = useGetAllQuery({
        key: KEYS.relatives, url: URLS.relatives
    })
    const relativeList = getSelectOptionsListFromData(get(relatives, `data.result`, []), 'ID', 'NAME')


    const {data: discounts} = useGetAllQuery({
        key: KEYS.discounts, url: URLS.discounts
    })
    const discountsList = getSelectOptionsListFromData(get(discounts, `data.result`, []), 'ID', 'NAME')


    const {
        mutate: getPersonalInfoRequest, isLoading: isLoadingPersonalInfo
    } = usePostQuery({listKeyId: KEYS.personalInfoProvider})


    const {
        mutate: getVehicleInfoRequest, isLoading: isLoadingVehicleInfo
    } = usePostQuery({listKeyId: KEYS.vehicleInfo})

    const {
        mutate: calculatePremiumRequest
    } = usePostQuery({listKeyId: KEYS.calculator, hideSuccessToast: true})

    const {
        mutate: getDriverInfoRequest, isLoading: isLoadingDriverInfo
    } = usePostQuery({listKeyId: KEYS.driverInfo})

    const {
        mutate: getDriverLicenseInfoRequest, isLoading: isLoadingDriverLicenseInfo
    } = usePostQuery({listKeyId: KEYS.driverLicenceProvider, hideErrorToast: true})
    const {
        mutate: createRequest, isLoading: isLoadingPost
    } = usePostQuery({listKeyId: KEYS.create})

    const getInfo = (type = 'owner') => {
        getPersonalInfoRequest({
                url: URLS.personalInfoProvider, attributes: {
                    birthDate: dayjs(birthDate).format('YYYY-MM-DD'), passportSeries, passportNumber
                }
            },
            {
                onSuccess: ({data}) => {
                    if (type === 'owner') {
                        setOwnerPerson(get(data, 'result'));
                    }
                },
                onError: () => {

                }
            }
        )
    }

    const getVehicleInfo = () => {
        if (govNumber && techPassportNumber && techPassportSeria) {
            getVehicleInfoRequest({
                    url: URLS.vehicleInfoProvider, attributes: {
                        govNumber: toUpper(govNumber),
                        techPassportNumber,
                        techPassportSeria: toUpper(techPassportSeria)
                    }
                },
                {
                    onSuccess: ({data}) => {
                        setVehicle(get(data, 'result'))
                    }
                }
            )
        } else {
            toast.warn('Please fill all fields')
        }
    }
    const getDriverInfo = () => {
        if (driverInps && driverPassportNumber && driverPassportSeries) {
            getDriverInfoRequest({
                    url: URLS.personalInfoProvider, attributes: {
                        birthDate: dayjs(driverInps).format('YYYY-MM-DD'),
                        passportSeries: driverPassportSeries,
                        passportNumber: driverPassportNumber
                    }
                },
                {
                    onSuccess: ({data}) => {
                        setDriver(get(data, 'result'))
                    }
                }
            )
        } else {
            toast.warn('Please fill all fields')
        }
    }

    const getDriverLicenseInfo = (_driverLicense, type = 'owner') => {
        if (type == 'owner') {
            if (get(_driverLicense, 'pinfl') && passportSeries && passportNumber) {
                getDriverLicenseInfoRequest({
                        url: URLS.driverLicenceProvider, attributes: {
                            pinfl: get(_driverLicense, 'pinfl'),
                            passportSeries,
                            passportNumber
                        }
                    },
                    {
                        onSuccess: ({data}) => {
                            setOwnerPerson(prev => ({...prev, license: get(data, 'result')}));
                        }
                    }
                )
            }
        } else {
            if (get(_driverLicense, 'pinfl') && get(_driverLicense, 'passportSeries') && get(_driverLicense, 'passportNumber')) {
                getDriverLicenseInfoRequest({
                        url: URLS.driverLicenceProvider, attributes: {
                            pinfl: get(_driverLicense, 'pinfl'),
                            passportSeries: get(_driverLicense, 'passportSeries'),
                            passportNumber: get(_driverLicense, 'passportNumber')
                        }
                    },
                    {
                        onSuccess: ({data}) => {
                            setDriver(prev => ({...prev, ...get(data, 'result')}))
                        }
                    }
                )
            }
        }
    }
    const calculatePremium = () => {
        calculatePremiumRequest({
                url: URLS.calculator, attributes: {
                    ...calculatorDTO,
                    driverNumberRestriction: get(calculatorDTO, 'driverNumberRestriction') == 0 ? false : true
                }
            },
            {
                onSuccess: ({data}) => {
                    setCost(get(data, 'result'))
                }
            }
        )
    }

    const addDriver = ({data}) => {
        if (get(data, 'driver.fullName.firstname')) {
            setDrivers(prev => [...prev, get(data, 'driver')])
            setVisible(false);
        }
    }
    const removeDriver = (index) => {
        setDrivers(drivers.filter((d, i) => i != index))
    }
    const getFieldData = (name, value) => {

        if (isEqual(name, 'insuranceTermId')) {
            setCalculatorDTO(prev => ({...prev, terms: parseInt(value)}))
        }

        if (isEqual(name, 'vehicle.techPassport.number')) {
            setTechPassportNumber(value)
        }
        if (isEqual(name, 'vehicle.techPassport.seria')) {
            setTechPassportSeria(toUpper(value))
        }
        if (isEqual(name, 'vehicle.govNumber')) {
            setGovNumber(toUpper(value))
        }
        if (isEqual(name, 'vehicle.typeId')) {
            setCalculatorDTO(prev => ({...prev, vehicleTypeId: value}))
        }
        if (isEqual(name, 'cost.discountId')) {
            setCalculatorDTO(prev => ({...prev, discount: parseInt(value)}))
        }
        if (isEqual(name, 'vehicle.regionId')) {
            setCalculatorDTO(prev => ({...prev, regionId: value}))
        }
        if (isEqual(name, 'details.driverNumberRestriction')) {
            setCalculatorDTO(prev => ({...prev, driverNumberRestriction: value}))
        }
        if (isEqual(name, 'applicant.person.passportData.seria')) {
            setDriverLicense(prev => ({...prev, passportSeries: value}))
        }
        if (isEqual(name, 'applicant.person.passportData.passportNumber')) {
            setDriverLicense(prev => ({...prev, passportNumber: value}))
        }
        if (isEqual(name, 'applicant.person.passportData.pinfl')) {
            setDriverLicense(prev => ({...prev, pinfl: value}))
        }
        setOtherParams(prev => ({...prev, [name]: value}))
    }

    const create = ({data}) => {
        const {
            accident,
            discount,
            birthDate,
            number,
            passportNumber,
            passportSeries,
            policies,
            rewardSum,
            rpmSum,
            seria,
            termCategories,
            terms,
            details,
            owner,
            agentReward,
            insuranceTermId,
            discountSum,
            insurancePremium,
            insurancePremiumPaidToInsurer,
            insuranceSum,
            ...rest
        } = data

        if (get(otherParams, 'details.driverNumberRestriction') != 0) {
            if ((drivers.length < 1) && !get(ownerDriver, 'licenseNumber') && !get(ownerDriver, 'licenseSeria')) {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false,
                    title: t('Cписок водителей не должны быть пустыми'),
                })
            } else if ((drivers.length >= 1) && !get(ownerDriver, 'licenseNumber') && !get(ownerDriver, 'licenseSeria')) {
                createRequest({
                        url: URLS.create, attributes: {
                            details: {
                                ...details,
                                issueDate: get(details, 'startDate'),
                                driverNumberRestriction: get(otherParams, 'details.driverNumberRestriction') == 0 ? false : true
                            },
                            drivers: [...drivers],
                            ...rest,
                            cost: get(cost, 'cost'),
                        }
                    },
                    {
                        onSuccess: ({data: response}) => {
                            if (get(response, 'result.osago_formId')) {
                                navigate(`/osago/view/${get(response, 'result.osago_formId')}`);
                            } else {
                                navigate(`/osago`);
                            }
                        }
                    }
                )
            } else {
                if (get(ownerDriver, 'licenseNumber') && get(ownerDriver, 'licenseSeria')) {
                    createRequest({
                            url: URLS.create, attributes: {
                                details: {
                                    ...details,
                                    issueDate: get(details, 'startDate'),
                                    driverNumberRestriction: get(otherParams, 'details.driverNumberRestriction') == 0 ? false : true
                                },
                                drivers: [...drivers, ownerDriver],
                                ...rest,
                                cost: get(cost, 'cost'),
                            }
                        },
                        {
                            onSuccess: ({data: response}) => {
                                if (get(response, 'result.osago_formId')) {
                                    navigate(`/osago/view/${get(response, 'result.osago_formId')}`);
                                } else {
                                    navigate(`/osago`);
                                }
                            }
                        }
                    )
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        timer: 2000,
                        showCancelButton: false,
                        showConfirmButton: false,
                        title: t('У собственника нет водительские права'),
                    })
                }
            }
        } else {
            createRequest({
                    url: URLS.create, attributes: {
                        details: {
                            ...details,
                            issueDate: get(details, 'startDate'),
                            driverNumberRestriction: get(otherParams, 'details.driverNumberRestriction') == 0 ? false : true
                        },
                        drivers: drivers.length == 0 ? undefined : drivers,
                        ...rest,
                        cost: get(cost, 'cost'),
                    }
                },
                {
                    onSuccess: ({data: response}) => {
                        if (get(response, 'result.osago_formId')) {
                            navigate(`/osago/view/${get(response, 'result.osago_formId')}`);
                        } else {
                            navigate(`/osago`);
                        }
                    }
                }
            )
        }
    }


    useEffect(() => {
        if (every(values(calculatorDTO), (_item) => !isNil(_item) && !isNaN(_item))) {
            calculatePremium()
        }
    }, [calculatorDTO])

    useEffect(() => {
        if (driverLicense) {
            getDriverLicenseInfo(driverLicense)
        }
    }, [driverLicense])
    useEffect(() => {
        if (driverPassportSeries && driverPassportNumber && get(driver, 'pinfl')) {
            getDriverLicenseInfo({
                pinfl: get(driver, 'pinfl'),
                passportNumber: driverPassportNumber,
                passportSeries: driverPassportSeries
            }, 'driver')
        }
    }, [driverPassportSeries, driverPassportNumber, get(driver, 'pinfl')])

    useEffect(() => {
        if (hasDrivers) {
            if (get(otherParams, 'applicant.person.fullName.firstname') && get(otherParams, 'applicant.person.fullName.lastname') && get(otherParams, 'applicant.person.fullName.middlename')) {
                setOwnerDriver({
                    fullName: {
                        firstname: get(otherParams, 'applicant.person.fullName.firstname'),
                        lastname: get(otherParams, 'applicant.person.fullName.lastname'),
                        middlename: get(otherParams, 'applicant.person.fullName.middlename'),
                    },
                    passportData: {
                        pinfl: get(otherParams, 'applicant.person.passportData.pinfl'),
                        seria: get(otherParams, 'applicant.person.passportData.seria'),
                        number: get(otherParams, 'applicant.person.passportData.number'),
                        issueDate: get(otherParams, 'applicant.person.passportData.issueDate'),
                        issuedBy: get(otherParams, 'applicant.person.passportData.issuedBy'),
                    },
                    licenseNumber: get(otherParams, 'applicant.person.licenseNumber'),
                    licenseSeria: get(otherParams, 'applicant.person.licenseSeria'),
                    licenseIssueDate: get(otherParams, 'applicant.person.licenseIssueDate'),
                    birthDate: get(otherParams, 'applicant.person.birthDate'),
                    gender: get(otherParams, 'applicant.person.gender'),
                    relative: 0,
                })
            }
        } else {
            setOwnerDriver(null)
        }
    }, [hasDrivers, otherParams])


    if (isLoadingRegion || isLoadingInsuranceTerms) {
        return <OverlayLoader/>
    }

    return (<>
        {(isLoadingPersonalInfo || isLoadingVehicleInfo || isLoadingPost) &&
            <OverlayLoader/>}
        <Section>
            <Row>
                <Col xs={12}>
                    <Form formRequest={create} getValueFromField={(value, name) => getFieldData(name, value)}
                          footer={<Flex className={'mt-32'}><Button onClick={() => navigate('/osago')} danger
                                                                    type={'button'}
                                                                    className={'mr-16'}>Отмена</Button><Button
                              type={'submit'}
                              className={'mr-16'}> Добавить</Button></Flex>}>
                        <Row gutterWidth={60} className={'mt-30'}>
                            <Col xs={12} className={'mb-25'}><Title>Информация о транспортном средстве:
                            </Title></Col>
                            <Col xs={4} style={{borderRight: '1px solid #DFDFDF'}}>
                                <Row align={'center'} className={'mb-25'}>
                                    <Col xs={5}>Государственный номер</Col>
                                    <Col xs={7}><Field defaultValue={govNumber}
                                                       property={{hideLabel: true, classNames: 'text-uppercase'}}
                                                       type={'input'}
                                                       name={'vehicle.govNumber'}/></Col>
                                </Row>

                                <Row align={'center'} className={'mb-25'}>
                                    <Col xs={5}>Серия тех.паспорта:</Col>
                                    <Col xs={7}><Field defaultValue={techPassportSeria}
                                                       property={{hideLabel: true, classNames: 'text-uppercase'}}
                                                       type={'input'}
                                                       name={'vehicle.techPassport.seria'}/></Col>
                                </Row>
                                <Row align={'center'} className={'mb-25'}>
                                    <Col xs={5}>Номер тех.паспорта:</Col>
                                    <Col xs={7}><Field defaultValue={techPassportNumber} property={{hideLabel: true}}
                                                       type={'input'}
                                                       name={'vehicle.techPassport.number'}/></Col>
                                </Row>
                                <Button type={'button'} onClick={() => getVehicleInfo()} className={'w-100'}>Получить
                                    данные</Button>
                            </Col>
                            <Col xs={8}>
                                <Row>
                                    <Col xs={4} className="mb-25">
                                        <Field defaultValue={get(vehicle, 'vehicleTypeId')} options={vehicleTypeList}
                                               params={{required: true}}
                                               label={'Вид транспорта'}
                                               type={'select'}
                                               name={'vehicle.typeId'}/>
                                    </Col>
                                    <Col xs={4} className="mb-25">
                                        <Field defaultValue={get(vehicle, 'modelName')} params={{required: true}}
                                               label={'Марка / модель'}
                                               type={'input'}
                                               name={'vehicle.modelCustomName'}/>
                                    </Col>
                                    <Col xs={4} className="mb-25">
                                        <Field defaultValue={get(vehicle, 'regionId')} options={regionList}
                                               params={{required: true}}
                                               label={'Регион регистрации'}
                                               type={'select'}
                                               name={'vehicle.regionId'}/>
                                    </Col>

                                    <Col xs={4} className="mb-25">
                                        <Field defaultValue={get(vehicle, 'issueYear')}
                                               property={{mask: '9999', maskChar: '_'}} params={{required: true}}
                                               label={'Год выпуска'}
                                               type={'input-mask'}
                                               name={'vehicle.issueYear'}/>
                                    </Col>
                                    <Col xs={4} className="mb-25">
                                        <Field defaultValue={get(vehicle, 'bodyNumber')} params={{required: true}}
                                               label={'VIN номер'}
                                               type={'input'}
                                               name={'vehicle.bodyNumber'}/>
                                    </Col>
                                    <Col xs={4} className="mb-25">
                                        <Field defaultValue={get(vehicle, 'engineNumber')} params={{required: true}}
                                               label={'Номер двигателя'}
                                               type={'input'}
                                               name={'vehicle.engineNumber'}/>
                                    </Col>
                                    <Col xs={4} className="mb-25">
                                        <Field defaultValue={get(vehicle, 'techPassportIssueDate')}
                                               params={{required: true}}
                                               label={'Дата выдачи тех.паспорта'}
                                               type={'datepicker'}
                                               name={'vehicle.techPassport.issueDate'}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row gutterWidth={60} className={'mt-15'}>
                            <Col xs={12} className={'mb-25'}><Title>Информация о владельце транспортного средства:
                            </Title></Col>
                            <Col xs={12}>
                                <Row>
                                    <Col xs={4}>
                                        <Flex>
                                            <h4 className={'mr-16'}>Владелец</h4>
                                            <Button onClick={() => setOwner('person')}
                                                    gray={!isEqual(owner, 'person')} className={'mr-16'}
                                                    type={'button'}>Физ. лицо</Button>
                                        </Flex>
                                    </Col>
                                    <Col xs={8} className={'text-right'}>
                                        <Flex justify={'flex-end'}>
                                            <Field params={{required: true}}
                                                   className={'mr-16'} style={{width: 75}}
                                                   property={{
                                                       hideErrorMsg: true,
                                                       hideLabel: true,
                                                       mask: 'aa',
                                                       placeholder: 'AA',
                                                       upperCase: true,
                                                       maskChar: '_',
                                                       onChange: (val) => setPassportSeries(upperCase(val))
                                                   }}
                                                   name={'applicant.person.passportData.seria'}
                                                   type={'input-mask'}
                                            />
                                            <Field params={{required: true}} property={{
                                                hideErrorMsg: true,
                                                hideLabel: true,
                                                mask: '9999999',
                                                placeholder: '1234567',
                                                maskChar: '_',
                                                onChange: (val) => setPassportNumber(val)
                                            }} name={'applicant.person.passportData.number'} type={'input-mask'}/>

                                            <Field params={{required: true}} className={'ml-15'}
                                                   property={{
                                                       hideErrorMsg: true,
                                                       hideLabel: true,
                                                       placeholder: 'Дата рождения',
                                                       onChange: (e) => setBirthDate(e)
                                                   }}
                                                   name={'applicant.person.birthDate'} type={'datepicker'}/>
                                            <Button onClick={() => getInfo()} className={'ml-15'} type={'button'}>Получить
                                                данные</Button>
                                        </Flex>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={12}>
                                <hr className={'mt-15 mb-15'}/>
                            </Col>

                            <Col xs={4} className={'mb-25'}>
                                <Field params={{required: true}}
                                       defaultValue={get(ownerPerson, 'firstNameLatin')}
                                       label={'Firstname'}
                                       type={'input'}
                                       name={'applicant.person.fullName.firstname'}/>
                            </Col>
                            <Col xs={4} className={'mb-25'}>
                                <Field params={{required: true}} defaultValue={get(ownerPerson, 'lastNameLatin')}
                                       label={'Lastname'} type={'input'}
                                       name={'applicant.person.fullName.lastname'}/>
                            </Col>
                            <Col xs={4} className={'mb-25'}>
                                <Field params={{required: true}}
                                       defaultValue={get(ownerPerson, 'middleNameLatin')}
                                       label={'Middlename'}
                                       type={'input'}
                                       name={'applicant.person.fullName.middlename'}/>
                            </Col>
                            <Col xs={4} className={'mb-25'}>
                                <Field defaultValue={get(ownerPerson, 'pinfl')} label={'ПИНФЛ'} type={'input'}
                                       name={'applicant.person.passportData.pinfl'}/>
                            </Col>
                            <Col xs={4} className={'mb-25'}>
                                <Field defaultValue={get(ownerPerson, 'issuedBy')} label={'Кем выдан паспорт'}
                                       type={'input'}
                                       name={'applicant.person.passportData.issuedBy'}/>
                            </Col>
                            <Col xs={4} className={'mb-25'}>
                                <Field defaultValue={get(ownerPerson, 'issueDate')} label={'Серия выдачи паспорта'}
                                       type={'datepicker'}
                                       name={'applicant.person.passportData.issueDate'}/>
                            </Col>
                            <Col xs={4} className={'mb-25'}>
                                <Field params={{required: true}}
                                       defaultValue={get(ownerPerson, 'gender')}
                                       options={genderList}
                                       label={'Gender'}
                                       type={'select'}
                                       name={'applicant.person.gender'}/>
                            </Col>
                            <Col xs={4} className={'mb-25'}>
                                <Field
                                    params={{
                                        required: true,
                                        pattern: {
                                            value: /^998(9[012345789]|6[125679]|7[01234569]|3[01234569])[0-9]{7}$/,
                                            message: 'Invalid format'
                                        }
                                    }}
                                    defaultValue={get(ownerPerson, 'phone')}
                                    label={'Phone'}
                                    type={'input'}
                                    property={{placeholder: '998XXXXXXXXX'}}
                                    name={'applicant.person.phoneNumber'}/>
                            </Col>
                            <Col xs={4} className={'mb-25'}>
                                <Field params={{required: true}}
                                       defaultValue={get(ownerPerson, 'address')}
                                       label={'Address'}
                                       type={'input'}
                                       name={'applicant.address'}/>
                            </Col>
                            <Col xs={4} className={'mb-25'}>
                                <Field
                                    defaultValue={get(isPensioner, 'data.result') == 1 ? '7' : get(isDisabled, 'data.result') == 1 ? '8' : '1'}
                                    params={{required: true}}
                                    label={'Наличие льгот'}
                                    type={'select'}
                                    options={discountsList}
                                    name={'cost.discountId'}/>
                            </Col>
                            <Col xs={4}>
                                <Field
                                    defaultValue={get(ownerPerson, 'license.licenseSeria')}
                                    label={'Серия вод.удостоверения'}
                                    type={'input'}
                                    name={'applicant.person.licenseSeria'}/>
                            </Col>
                            <Col xs={4}>
                                <Field
                                    defaultValue={get(ownerPerson, 'license.licenseNumber')}
                                    label={'Номер вод.удостоверения'}
                                    type={'input'}
                                    name={'applicant.person.licenseNumber'}/>
                            </Col>
                            <Col xs={4} className={'mt-15'}>
                                <Field
                                    defaultValue={get(ownerPerson, 'license.issueDate')}
                                    params={{required: true}}
                                    label={'Дата вод.удостоверения'}
                                    type={'datepicker'}
                                    name={'applicant.person.licenseIssueDate'}/>
                            </Col>

                        </Row>

                        <Row gutterWidth={60} className={'mt-30'}>
                            <Col xs={12} className={'mb-25'}><Title>Информация о допущенных к управлению транспортным
                                средством лицах:
                            </Title></Col>
                            <Col xs={12}>
                                <Info size={18} /><span> Уважаемый клиент! Необходимо указать в полисе близких родственников владельца транспортного средства (отец-мать, братья, сёстры, муж(жена), сыновья, дочери) во избежание требования доверенности со стороны Службы безопасности дорожного движения по вышеуказанным лицам.Пожалуйста, внесите информацию о близких родственниках в данный раздел.</span>
                                <Flex justify={'flex-end'}>
                                    <Field label={'Количество лиц, допущенных к управлению транспортным средством'}
                                           defaultValue={1}
                                           options={[{value: 1, label: 'Ограничено'}, {
                                               value: 0,
                                               label: 'Не ограничено'
                                           }]} type={'radio-group'}
                                           name={'details.driverNumberRestriction'}/>
                                    <Button
                                        onClick={() => setVisible(true)} className={'ml-15'}
                                        type={'button'}>Добавить</Button></Flex>
                            </Col>
                            <Col xs={12} className={'mt-15'}><Checkbox
                                disabled={get(otherParams, 'details.driverNumberRestriction') == 0}
                                checked={get(otherParams, 'details.driverNumberRestriction') == 0 ? false : hasDrivers}
                                onChange={(e) => setHasDrivers(e.target.checked)}/><strong> Владельца
                                транспортного
                                средства нужно добавить в список
                                водителей {hasDrivers && get(ownerDriver, 'fullName.middlename') && get(ownerDriver, 'fullName.firstname') && get(ownerDriver, 'fullName.lastname') && !get(ownerDriver, 'licenseNumber') && !get(ownerDriver, 'licenseSeria') &&
                                    <span className={'text-danger'}>( У собственника нет водительские права )</span>}
                            </strong></Col>
                            <Col xs={12}>
                                <div className={'horizontal-scroll mt-15 '}>
                                    <Table bordered hideThead={false}
                                           thead={['Фамилия ', 'Имя', 'Отчество', 'Степень родства', 'Действие']}>
                                        {(get(otherParams, 'details.driverNumberRestriction') != 0) && get(ownerDriver, 'licenseNumber') && get(ownerDriver, 'licenseSeria') &&
                                            <tr>
                                                <td>{get(ownerDriver, 'fullName.lastname')}</td>
                                                <td>{get(ownerDriver, 'fullName.firstname')}</td>
                                                <td>{get(ownerDriver, 'fullName.middlename')}</td>
                                                <td>{get(find(relativeList, (r) => get(r, 'value') == get(ownerDriver, 'relative')), 'label')}</td>
                                                <td></td>
                                            </tr>}
                                        {

                                            drivers.map((item, index) => <tr>
                                                <td>{get(item, 'fullName.lastname')}</td>
                                                <td>{get(item, 'fullName.firstname')}</td>
                                                <td>{get(item, 'fullName.middlename')}</td>
                                                <td>{get(find(relativeList, (r) => get(r, 'value') == get(item, 'relative')), 'label')}</td>
                                                <td><Trash2 onClick={() => removeDriver(index)}
                                                            className={'cursor-pointer'} color={'red'}/></td>
                                            </tr>)
                                        }
                                    </Table>
                                </div>
                            </Col>
                        </Row>

                        <Row gutterWidth={60} className={'mt-30'}>
                            <Col xs={12} className={'mb-15'}><Title>Срок страхования:
                            </Title></Col>
                            <Col xs={4} className={'mb-25'}>
                                <Field defaultValue={'1'} disabled options={insuranceTermsList}
                                       params={{required: true}}
                                       label={'Срок страхования'}
                                       type={'select'}
                                       name={'insuranceTermId'}/>
                            </Col>

                            <Col xs={4} className={'mb-25'}>
                                <Field
                                    label={'Дата начала покрытия'}
                                    params={{required: true}}
                                    property={{
                                        onChange: (val) => setPoliceStartDate(val),
                                        dateFormat: 'dd.MM.yyyy',
                                        minDate: new Date()
                                    }}
                                    type={'datepicker'}
                                    name={'details.startDate'}/>
                            </Col>

                            <Col xs={4}>
                                <Field
                                    label={'Дата окончания покрытия'}
                                    params={{required: true}}
                                    defaultValue={getEndDateByInsuranceTerm(find(get(insuranceTerms, `data.result`, []), (_insuranceTerm) => get(_insuranceTerm, 'ID') == get(calculatorDTO, 'terms')), policeStartDate)}
                                    disabled={!isEqual(insuranceTerm, 6)}
                                    property={{dateFormat: 'dd.MM.yyyy'}} type={'datepicker'}
                                    name={'details.endDate'}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} className={'mb-25'}>
                                <Field
                                    defaultValue={get(cost, 'sum.insuranceSum', 0)}
                                    property={{disabled: true}}
                                    label={'Страховая сумма'}
                                    type={'number-format-input'}
                                    name={'insuranceSum'}/>
                            </Col>
                            <Col xs={6} className={'mb-25'}>
                                <Field
                                    defaultValue={get(cost, 'sum.insurancePremium', 0)}
                                    property={{disabled: true}}
                                    label={'Страховая премия'}
                                    type={'number-format-input'}
                                    name={'insurancePremium'}/>
                            </Col>
                            <Col xs={6} className={'mb-25'}>
                                <Field
                                    defaultValue={get(cost, 'sum.discountSum', 0)}
                                    property={{disabled: true}}
                                    label={'Скидка'}
                                    type={'number-format-input'}
                                    name={'discountSum'}/>
                            </Col>
                            <Col xs={6} className={'mb-25'}>
                                <Field
                                    defaultValue={get(cost, 'sum.insurancePremiumPaidToInsurer', 0)}
                                    property={{disabled: true}}
                                    label={'Премия к оплате'}
                                    type={'number-format-input'}
                                    name={'insurancePremiumPaidToInsurer'}/>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Modal title={'Добавить водителя'} hide={() => setVisible(false)} visible={visible}>
                {isLoadingDriverInfo && <OverlayLoader/>}
                <Form
                    formRequest={addDriver}
                    getValueFromField={(value, name) => getFieldData(name, value)}
                    footer={<Flex className={'mt-32'}><Button>Добавить</Button></Flex>}>
                    <Row>
                        <Col xs={12} className={' mt-15'}>
                            <Flex>
                                <Field onChange={(e) => setDriverPassportSeries(upperCase(e.target.value))}
                                       className={'mr-16'} style={{width: 75}}
                                       property={{
                                           hideLabel: true, mask: 'aa', placeholder: 'AA', maskChar: '_'
                                       }}
                                       name={'driver.passportData.seria'}
                                       type={'input-mask'}
                                />
                                <Field className={'mr-16'} onChange={(e) => setDriverPassportNumber(e.target.value)}
                                       property={{
                                           hideLabel: true,
                                           mask: '9999999',
                                           placeholder: '1234567',
                                           maskChar: '_'
                                       }} name={'driver.passportData.number'} type={'input-mask'}/>
                                <Field params={{required: true}} className={'mr-16'}
                                       property={{
                                           hideErrorMsg: true,
                                           hideLabel: true,
                                           placeholder: 'Дата рождения',
                                           onChange: (e) => setDriverInps(e)
                                       }}
                                       name={'driver.birthDate'} type={'datepicker'}/>

                                <Button type={'button'} onClick={() => getDriverInfo()} className={'ml-15'}
                                >Получить
                                    данные</Button>
                            </Flex></Col>
                        <Col xs={4} className={'mt-15'}>
                            <Field params={{required: true}}
                                   defaultValue={get(driver, 'lastNameLatin')} label={'Фамилия'}
                                   type={'input'}
                                   name={'driver.fullName.lastname'}/>
                        </Col>
                        <Col xs={4} className={'mt-15'}>
                            <Field params={{required: true}}
                                   defaultValue={get(driver, 'firstNameLatin')} label={'Имя'}
                                   type={'input'}
                                   name={'driver.fullName.firstname'}/>
                        </Col>
                        <Col xs={4} className={'mt-15'}>
                            <Field params={{required: true}}
                                   defaultValue={get(driver, 'middleNameLatin')} label={'Отчество'}
                                   type={'input'}
                                   name={'driver.fullName.middlename'}/>
                        </Col>
                        <Col xs={4} className={'mt-15'}>
                            <Field label={'ПИНФЛ'} defaultValue={get(driver, 'pinfl')} params={{required: true}}
                                   property={{
                                       mask: '99999999999999',
                                       placeholder: 'ПИНФЛ',
                                       maskChar: '_'
                                   }} name={'driver.passportData.pinfl'} type={'input-mask'}/>
                        </Col>
                        <Col xs={4} className={'mt-15'}>
                            <Field defaultValue={get(driver, 'startDate')} params={{required: true}}
                                   label={'Дата выдачи'} type={'datepicker'}
                                   name={'driver.passportData.issueDate'}/>
                        </Col>

                        <Col xs={4} className={'mt-15'}>
                            <Field options={genderList} defaultValue={get(driver, 'gender')} params={{required: true}}
                                   label={'Gender'} type={'select'}
                                   name={'driver.gender'}/>
                        </Col>
                        <Col xs={4} className={'mt-15'}>
                            <Field defaultValue={get(driver, 'issuedBy')} params={{required: true}}
                                   label={'Issued by'} type={'input'}
                                   name={'driver.passportData.issuedBy'}/>
                        </Col>

                        <Col xs={4} className={'mt-15'}>
                            <Field defaultValue={get(driver, 'licenseSeria', '')}
                                   label={'Серия вод.удостоверения'}
                                   type={'input'}
                                   name={'driver.licenseSeria'}/>
                        </Col>
                        <Col xs={4} className={'mt-15'}>
                            <Field defaultValue={get(driver, 'licenseNumber', '')}
                                   label={'Номер вод.удостоверения'}
                                   type={'input'}
                                   name={'driver.licenseNumber'}/>
                        </Col>
                        <Col xs={4} className={'mt-15'}>
                            <Field defaultValue={get(driver, 'issueDate', '')}
                                   label={'Дата вод.удостоверения'}
                                   type={'datepicker'}
                                   name={'driver.licenseIssueDate'}/>
                        </Col>
                        <Col xs={4} className={'mt-15'}>
                            <Field params={{required: true, valueAsNumber: true}} options={relativeList}
                                   label={'Степень родства'}
                                   type={'select'}
                                   name={'driver.relative'}/>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Section>
    </>);
};

export default OsagaCreateContainer;